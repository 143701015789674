import React from "react";
import { Redirect } from "react-router-dom";
import sessionRoutes from "./views/sessions/SessionRoutes";
import propertyRoutes from "./views/properties/PropertyRoutes";
import goFindRoutes from "./views/goFind/goFindRoutes";
import PriceRoutes from "./views/pricing/priceRoutes";
import contactUsRoutes from "./views/contactus/contactRoutes";
import URLS from "./views/urls/urlsRoutes";
import OutputRoutes from "./views/output/outputRoutes";
import supportedURLS from "./views/supportedurls/supporturlRoutes";
import youtubeURLS from "./views/youtube/youtubeRoutes";
import spotifyURLS from "./views/spotify/spotifyRoutes";
import FaqRoutes from "./views/faq/FaqRoutes";
import HomeRoutes from "./views/home/HomeRoutes";
import SocialRoutes from "./views/ManageSocialURLs/routes/manageSocialUrls.routes";
import ScheduleRoutes from "./views/ManageSchedulePosts/routes/manageSchedulePosts.routes";
import TeamSocials from "./views/TeamSocials";

/*
  Required if you are going to use Evelynn Landing page
  https://themeforest.net/item/react-landing-material-ui-react-saasproduct-landing-page/23847400
*/

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/session/signin" />,
    restricted: false,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  {
    path: "/teams/social",
    render: () => <TeamSocials />,
    exact: true,
    restricted: false,
  },
  ...HomeRoutes,
  ...sessionRoutes,
  ...propertyRoutes,
  ...goFindRoutes,
  ...supportedURLS,
  ...youtubeURLS,
  ...spotifyURLS,
  ...URLS,
  ...PriceRoutes,
  ...contactUsRoutes,
  ...FaqRoutes,
  ...SocialRoutes,
  ...ScheduleRoutes,
  ...OutputRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
