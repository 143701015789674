import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";


const YoutubeURL = EgretLoadable({
    loader: () => import("./YoutubeURL")
});

const youtubeURLS = [
    {
        path: "/urls/youtube",
        exact: true,
        render: (props) => localStorage.getItem('token') ? <YoutubeURL /> : <Redirect to='/session/signin' />
    }
]

export default youtubeURLS