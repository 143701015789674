import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const SpotifyURL = EgretLoadable({
    loader: () => import("./SpotifyURL")
});

const spotifyURLS = [
    {
        path: "/urls/spotify",
        exact: true,
        render: (props) => localStorage.getItem('token') ? <SpotifyURL /> : <Redirect to='/session/signin' />
    }
]

export default spotifyURLS